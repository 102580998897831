$(document).ready(function () {
	$('body').find('[data-toggle="quantity"]').each(function () {
		var $this = $(this),
			$down = $this.find('.quantity-down'),
			$up = $this.find('.quantity-up'),
			$quantity = $this.find('.quantity');

		var toggleQuantity = function (direction) {
			var value = parseInt($quantity.val());
			if (direction === 'down') {
				value = value - 1;
			} else if (direction === 'up') {
				value = value + 1;
			}
			if (value < 0) {
				value = 0;
			}
			$quantity.val(value);
		};

		if ($quantity.length > 0) {
			$down.on('click', function () {
				toggleQuantity('down');
			});
			$up.on('click', function () {
				toggleQuantity('up');
			});
		}
	});
});
